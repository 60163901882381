import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import { createDomElementGetter } from './dom-element-getter.js'
import { createWorkfrontLifecycles } from './workfront-lifecycles.js'

/**
 * @param {object} options
 * @returns {{bootstrap: Array<any>, mount: Array<any>, unmount: Array<any>}}
 */
export function createReactLifecycles(options) {
  const domElementGetter = options.domElementGetter
    ? options.domElementGetter
    : createDomElementGetter(options)

  const workfrontLifecycles = createWorkfrontLifecycles({
    ...options,
    domElementGetter,
  })

  const reactLifecycles = singleSpaReact({
    ReactDOM,
    React,
    ...options,
    domElementGetter,
  })

  return {
    bootstrap: [...workfrontLifecycles.bootstrap, reactLifecycles.bootstrap],
    mount: [...workfrontLifecycles.mount, reactLifecycles.mount],
    /*
     * react lifecycles are going first here so that react can unmount react from the node and then
     * the workfront lifecycles remove the node.
     */
    unmount: [reactLifecycles.unmount, ...workfrontLifecycles.unmount],
  }
}
