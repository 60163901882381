import { createDomElementGetter } from './dom-element-getter.js'
import { saveBoundMountParcelMethod, clearBoundMountParcelMethod } from './single-spa-parcel-helpers.js'
export { getMountParcelBoundToAnApplication, getMountParcelMethod} from './single-spa-parcel-helpers.js'

/**
 * @param {object} [options={}]
 * @returns {{bootstrap: [], mount: Array<any>, unmount: Array<any>}}
 */
export function createWorkfrontLifecycles(options = {}) {
  const boundDomElementGetter = options.domElementGetter
    ? options.domElementGetter
    : createDomElementGetter(options)
  return {
    bootstrap: [],
    mount: [
      saveBoundMountParcelMethod,
      (ssOptions) => mountDomNodeMethod(ssOptions, boundDomElementGetter),
    ],
    unmount: [
      clearBoundMountParcelMethod,
      (ssOptions) => unmountDomNodeMethod(ssOptions, boundDomElementGetter),
    ],
  }
}

function mountDomNodeMethod(ssOptions, domElementGetter) {
  return Promise.resolve().then(() => {
    domElementGetter(ssOptions)
    return undefined
  })
}

function unmountDomNodeMethod(ssOptions, domElementGetter) {
  return Promise.resolve().then(() => {
    const node = domElementGetter(ssOptions)
    node.remove()
    return undefined
  })
}

export const __testing_only_unmount = unmountDomNodeMethod
export const __testing_only_mount = mountDomNodeMethod
