const idealLocation = '#active-applications'
const previousIdealLocation = '#page-content'
const mainContent = '#main-content'
const idPrefix = ''
const standardClassName = 'wf-mfe-app'
const defaultOptions = {
  createRootNode: defaultCreateRootNode,
}

export function createDomElementGetter(options = {}) {
  const combinedOptions = { ...defaultOptions, ...options }
  return domElementGetter.bind(null, combinedOptions)
}

export function getID(singleSpaOptions) {
  const appName = singleSpaOptions.appName || singleSpaOptions.name
  return `${idPrefix}${appName.replace('@wf-mfe/', 'wf-mfe__')}`
}

function domElementGetter(options, singleSpaOptions) {
  const htmlId = getID(singleSpaOptions)
  let domElement = document.getElementById(htmlId)
  if (!domElement) {
    // dom element must be created
    const { rootNode = {} } = options
    const { type = 'div' } = rootNode
    const className = generateClassName(rootNode.className)
    domElement = options.createRootNode({ id: htmlId, type, className })
    validateCreatedNode(domElement, htmlId)
    appendChildToNodeByPreference(domElement)
  }

  return domElement
}

export function validateCreatedNode(domElement, id) {
  if (!domElement) {
    throw new Error('Created DOM node is undefined')
  }
  if (!domElement.className.includes(standardClassName)) {
    throw new Error('Created DOM node must include the standardClassName')
  }
  if (!domElement.id.includes(id)) {
    throw new Error('Create DOM node does not contain the required id')
  }
}

function generateClassName(providedClassName) {
  if (providedClassName) {
    return providedClassName.includes(standardClassName)
      ? providedClassName
      : `${standardClassName} ${providedClassName}`
  } else {
    return standardClassName
  }
}

function defaultCreateRootNode({ id, type, className }) {
  const domElement = document.createElement(type)
  domElement.id = id
  domElement.className = className
  return domElement
}

function appendChildToNodeByPreference(child) {
  const idealNode = document.querySelector(idealLocation)
  const mainContentNode = document.querySelector(mainContent)
  const previousIdealNode = document.querySelector(previousIdealLocation)
  if (idealNode) {
    idealNode.appendChild(child)
  } else if (previousIdealNode) {
    previousIdealNode.appendChild(child)
  } else if (mainContentNode) {
    mainContentNode.appendChild(child)
  } else {
    document.body.appendChild(child)
  }
}
