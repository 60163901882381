/*
 * Single spa parcels should be mounted via a function that is created when an application is mounted.
 * That created function ties a parcel to an application so that when an application is unmounted so is the parcel
 * This area serves as a cache that captures and saves those methods as they are created so we can use them as needed.
 *
 * This is necessary because of a combination of single-spa-workfront, the layout mfe, and single-spa-react
 * Instead of asking developers who want to use a parcel to use `mountRootParcel` or manage these created methods themselves this helper should make
 * it easy to mount a parcel in the context of a given application.
 *
 * Ideally parcels aren't used a lot but not supporting them is also a problem.
 */
import { mountRootParcel } from 'single-spa'

const boundMountParcelMethods = {}

export function saveBoundMountParcelMethod(ssOptions) {
  const { name, mountParcel } = ssOptions
  boundMountParcelMethods[name] = mountParcel
  return Promise.resolve()
}

export function clearBoundMountParcelMethod(ssOptions) {
  const { name } = ssOptions
  boundMountParcelMethods[name] = undefined
  return Promise.resolve()
}

/**
 * @param {string} [name]
 * @returns {function}
 */
export function getMountParcelBoundToAnApplication(name) {
  return boundMountParcelMethods[name]
}

/*
 * Most of the time people will use this method which features a handy fallback to mountRootParcel
 * If the given application name is available to mount a parcel then use it, else fallback to mountRootParcel.
 *  This isn't a perfect solution but it's the best we can do with the existing single-spa API
 */
export function getMountParcelMethod(name) {
  return getMountParcelBoundToAnApplication(name) || mountRootParcel
}

export function __testing_only_clear() {
  Object.keys(boundMountParcelMethods).forEach((key) => {
    delete boundMountParcelMethods[key]
  })
}
